import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        path: '/',
        name: 'Home',
        component: isMobile() ? () =>
            import ('../views/Mobile/Aili.vue') : () =>
            import ('../views/Aili.vue')
    },
    {
        path: '/home',
        name: 'TrueHome',
        component: isMobile() ? () =>
            import ('../views/Mobile/Aili.vue') : () =>
            import ('../views/Aili.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: isMobile() ? () =>
            import ('../views/Mobile/About.vue') : () =>
            import ('../views/About.vue')

    },
    {
        path: '/contact',
        name: 'Contact',
        component: isMobile() ? () =>
            import ('../views/Mobile/Contact.vue') : () =>
            import ('../views/Contact.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

function isMobile() {
    let flag = document.body.clientWidth < 500
    return flag
}

export default router