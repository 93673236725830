<template>
  <div class="foot u-bg">
    <div class="row" style="padding-top: 20px;">
      <div class="col-2"></div>
      <div class="col-5">
        <p style="color:#fff; text-align:left; font-weight: bold;">关于我们</p>
        <hr color="#ffffff" width="16%" align="left" />
        <p style="color:#fff; text-align:left; font-size:14px;">超厚抗剐蹭，暴晒不龟裂</p>
        <p style="color:#fff; text-align:left; font-size:14px;">十年不变黄，十年不变哑</p>
        <p style="color:#fff; text-align:left; font-size:14px;">揭膜不留残胶，官方十年质保</p>
      </div>
      <div class="col-5">
        <p style="color:#fff; text-align:left; font-weight: bold;">联系方式</p>
        <hr color="#ffffff" width="16%" align="left" />
        <p style="color:#fff; text-align:left; font-size:14px;">
          <span style="font-weight: bold;">地址：</span>黑龙江省哈尔滨市南岗区477号奔马汽配城负一层F30号
        </p>
        <p style="color:#fff; text-align:left; font-size:14px;">
          <span style="font-weight: bold;">电话：</span>180 4505 7555
        </p>
        <p style="color:#fff; text-align:left; font-size:14px;">
          <span style="font-weight: bold;">官网：</span>www.ailippf.com
        </p>
      </div>
    </div>
    <hr color="#ffffff" />
    <div class="row">
      <div class="col-2"></div>
      <div class="col-10">
        <p style="color:#fff; font-size:13px; text-align:left;">
          2020-2023 © AILIPPF.COM All Rights Reserved. Ai·Li高性能汽车膜
          <a
            style="margin-left: 50px; color:#fff; font-size:13px;"
            href="https://beian.miit.gov.cn/#/Integrated/index"
          >吉ICP备2021008123号-1</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Foot',
  methods: {
    switchMenu(path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>
@import '../assets/css/footer.css';
</style>
