<template>
  <div class="head">
    <nav
      class="navbar fixed-top navbar-expand navbar-dark bg-dark"
      style="height: 58px"
    >
      <a class="navbar-brand logo-navbar" href="#">
        <span
          style="font: 30 30px 'Comic Sans MS'; text-shadow: 0 0 20px #fdec84"
        >
          Ai·Li高性能汽车膜
        </span>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item">
            <a class="head-item__link" href="/" @click="switchMenu('/')"
              >首页</a
            >
          </li>
          <li class="nav-item">
            <a class="head-item__link" href="contact">联系我们</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Head",
  methods: {
    switchMenu(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>

<style scoped>
@import "../assets/css/header.css";
</style>
