<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand" href="#">
        <span
          style="font: 25 25px 'Comic Sans MS'; text-shadow: 0 0 20px #fdec84"
        >
          Ai·Li高性能汽车膜
        </span>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="home">首页</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="contact">联系我们</a>
          </li>
        </ul>
      </div>
    </nav>
    <!-- <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <a class="navbar-brand" href="#">
        <img
          src="../../assets/img/logo.png"
          width="140"
          height="30"
          class="d-inline-block align-top"
          alt
        />
      </a>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <a class="nav-link active" href="#">
            Home
            <span class="sr-only">(current)</span>
          </a>
          <a class="nav-link" href="#">Features</a>
          <a class="nav-link" href="#">Pricing</a>
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </div>
      </div>
    </nav>-->
  </div>
</template>

<script>
export default {
  name: "Head",
  methods: {
    switchMenu(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>

<style scoped>
</style>
